import { Box, Typography } from '@mui/material';
import Modal, { ModalProps } from 'components/Modal';
import React, { Fragment, ReactNode } from 'react';
import Carousel from 'react-material-ui-carousel';
import Image1 from 'assets/images/modal/introduction-modal__image--1.png';
import Image2 from 'assets/images/modal/introduction-modal__image--2.png';
import Image3 from 'assets/images/modal/introduction-modal__image--3.png';
import NavigationButton from 'components/NavigationButton';

export type IntroductionModalProps = Omit<ModalProps, 'children'> & {
  index: number;
  onChangeIndex: (index: number) => any;
};
type IntroductionModalItemProps = {
  text: ReactNode;
  svg: ReactNode;
};

function Item(props: IntroductionModalItemProps) {
  return (
    <>
      <Box>
        <Typography
          textAlign={'center'}
          sx={{
            fontSize: '16px',
            lineHeight: 26 / 16,
            fontWeight: 500,
            color: '#000000',
          }}
        >
          {props.text}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          m: 'auto',
          mt: 2,
          '> img': {
            display: 'block',
            height: '230px',
            margin: '-20px auto',
          },
        }}
      >
        {props.svg}
      </Box>
    </>
  );
}

export default function IntroductionModal(props: IntroductionModalProps) {
  const { index, onChangeIndex, ...otherProps } = props;
  const closeButtonHandle = () => {
    props.onClose && props.onClose({}, 'backdropClick');
  };
  const nextButtonHandle = () => {
    if (props.index < 2) props.onChangeIndex(props.index + 1);
    else {
      closeButtonHandle();
    }
  };
  const items = [
    {
      text: (
        <>
          スマホから受付/視力測定の
          <br />
          順番予約ができます。
        </>
      ),
      image: <img src={Image1} alt="" />,
    },
    {
      text: (
        <>
          視力測定の順番になったら、
          <br />
          LINEでお呼び出しの通知をします。
        </>
      ),
      image: <img src={Image2} alt="" />,
    },
    {
      text: (
        <>
          PICK UP LOCKERで受け取れる
          <br />
          QRコードを発行します。
        </>
      ),
      image: <img src={Image3} alt="" />,
    },
  ];

  return (
    <Modal {...otherProps}>
      <Box sx={{ position: 'relative', pt: 10, pb: 10 }}>
        <Box sx={{ mt: 4 }}>
          <Carousel
            autoPlay={false}
            index={props.index}
            navButtonsAlwaysInvisible={true}
            indicators={false}
            cycleNavigation={false}
            prev={(now, previous) => {
              props.onChangeIndex(now || 0);
            }}
            next={(now, previous) => {
              props.onChangeIndex(now || 0);
            }}
            onChange={(now, previous) => {
              props.onChangeIndex(now || 0);
            }}
          >
            {items.map((item, i) => (
              <Item key={i} text={item.text} svg={item.image} />
            ))}
          </Carousel>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {items.map((item, i) => (
            <Box
              key={i}
              onClick={() => props.onChangeIndex(i)}
              sx={{
                p: '5px',
              }}
            >
              <Box
                sx={{
                  borderRadius: '9999px',
                  transition: '0.25s',
                  bgcolor: i === props.index ? '#EE4D4D' : '#E0E0E0',
                  width: '12px',
                  height: '12px',
                }}
              ></Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: '220px',
            m: 'auto',
            mt: 2,
          }}
        >
          {props.index < 2 && (
            <NavigationButton
              labelColor={'#000000'}
              backgroundColor={'#E0E0E0'}
              onClick={nextButtonHandle}
            >
              次へ
            </NavigationButton>
          )}
          {props.index >= 2 && (
            <NavigationButton
              labelColor={'#000000'}
              backgroundColor={'#E0E0E0'}
              onClick={closeButtonHandle}
            >
              閉じる
            </NavigationButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
